<template>
    <div>
        <a-card style="margin-bottom:20px">
            <a-form layout="inline">
                <a-form-item class="dep" label="标题:">
                    <a-input v-model="searchForm.title" placeholder="请输入标题"></a-input>
                </a-form-item>
                <a-form-item
                    label="主播"
                >
                    <a-select
                        v-model="searchForm.anchor"
                        placeholder="请选择"
                        style="width:160px"
                    >
                        <a-select-option v-for="d in robots" :key="d.user_id" :value="d.user_id">
                        {{ d.username }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item
                    label="直播状态"
                >
                    <a-select
                        v-model="searchForm.status"
                        placeholder="请选择"
                        style="width:160px"
                    >
                        <a-select-option value="all">
                            全部
                        </a-select-option>
                        <a-select-option value="0">
                            未开始
                        </a-select-option>
                        <a-select-option value="1">
                            直播中
                        </a-select-option>
                        <a-select-option value="2">
                            直播结束
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="创建时间">
                    <a-range-picker @change="handleDateChange" v-model="dateRange"/>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" icon="search" @click="handleClickSearch">
                        搜索
                    </a-button>
                </a-form-item>
                <a-form-item>
                    <a-button icon="reload" @click="handleReset">
                        重置
                    </a-button>    
                </a-form-item>
            </a-form>
        </a-card>
         <a-card>
            <a-row style="text-align:right;margin-bottom:20px;">
                <a-button type="primary" icon="plus" @click="handleClickAdd">新建直播</a-button>
            </a-row>
             <a-table
                :columns="columns"
                :data-source="tableData"
                :row-key="(record) => record._id"
                :pagination="pagination"
                :loading="loading"
                @change="handleTableChange"
                >
                <div slot="time" slot-scope="text">
                    {{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                </div>
                <div slot="long" slot-scope="text,record">
                    {{getTimeLong(record)}}
                </div>
                <div slot="anchor" slot-scope="text">
                    {{formatAnchor(text)}}
                </div>
                <a-tag slot="tag" slot-scope="text" color="blue">{{text}}</a-tag>
                <a-tag slot="status" slot-scope="text" :color="text===0?'blue':text===1?'green':'red'">{{text===0?'未开始':text===1?'直播中':'直播结束'}}</a-tag>
                <template slot="action"
                slot-scope="text,record">
                    <div class="operation-wrapper">
                        <a @click="handleGetDetail(record)" >详情</a>
                    </div>  
                </template>
            </a-table>
        </a-card>
        <a-modal v-model="visible" title="新建直播" width="1000px" @ok="handleSubmit">
            <a-form>
                <a-row>
                    <a-col :span="12">
                        <a-form-item 
                            label="标题" 
                            :label-col="{span:4}" 
                            :wrapper-col="{ span: 14 }"
                            >
                            <a-input style="width:300px" v-model="addForm.title" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item 
                            label="描述" 
                            :label-col="{span:4}" 
                            :wrapper-col="{ span: 14 }"
                            >
                            <a-input style="width:300px" v-model="addForm.desc" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="12">
                        <a-form-item
                            label="主播"
                            :label-col="{span:4}" 
                            :wrapper-col="{ span: 14}"
                        >
                            <robot-selected style="width:300px" :init="visible" @handleSelected="robotSelected"></robot-selected>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item
                            label="直播助手"
                            :label-col="{span:4}" 
                            :wrapper-col="{ span: 14}"
                        >
                            <a-select v-model="addForm.room" style="width: 300px" @change="selectedRoom">
                                <a-select-option v-for="item in rooms" :key="item._id" :value="item.conversation_id">
                                    {{item.nickname}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                
                
                <!-- <a-form-item
                    label="图片"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 14 }"
                >
                    <a-upload
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept='.jpg,.jpeg,.png'
                        :show-upload-list="false"
                        action="scrm/upload.file"
                        :before-upload="beforeUpload"
                        @change="handleImgChange"
                    >
                        <img v-if="addForm.img" :src="addForm.img" alt="avatar" style="height:100px;width:100px" />
                        <div v-else>
                        <a-icon :type="imgLoading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">
                            上传
                        </div>
                        </div>
                    </a-upload>
                </a-form-item> -->
                
                
                 <a-form-item
                    label="邀请群"
                    :label-col="{span:2}" 
                    :wrapper-col="{ span: 20 }"
                >
                   <mult-choose :selectedValue="addForm.room_list" :init="visible" @handleSelected="chooseMult"></mult-choose>
                </a-form-item>
                <a-form-item
                    label="邀请人"
                    :label-col="{span:2}" 
                    :wrapper-col="{ span: 20 }"
                >
                    <customer-choose :selectedValue="addForm.user_list" :init="visible" @handleSelected="chooseCustomer"></customer-choose>
                 </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="detailVisible" title="直播配置详情" :footer="null" width="600px">
                    <div class="detail-view">
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">标题:</a-col>
                            <a-col class="value" :span="14">{{detailObj.title}}</a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">描述:</a-col>
                            <a-col class="value" :span="14">{{detailObj.desc}}</a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">主播:</a-col>
                            <a-col class="value" :span="14">{{formatAnchor(detailObj.anchor)}}</a-col>
                        </a-row>
                        <a-row class="detail-row" v-if="detailObj.url">
                            <a-col class="label" :span="6">直播地址:</a-col>
                            <a-col class="value text" :span="14"><a :href="detailObj.url" target="_blank">{{detailObj.url}}</a></a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">创建时间:</a-col>
                            <a-col class="value" :span="14">
                                {{detailObj.create_time | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                            </a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">直播开始时间:</a-col>
                            <a-col class="value" :span="14">
                                {{detailObj.start_time | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                            </a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">直播结束时间:</a-col>
                            <a-col class="value" :span="14">
                                {{detailObj.end_time | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                            </a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">直播时长:</a-col>
                            <a-col class="value" :span="14">
                                {{getTimeLong(detailObj)}}
                            </a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">邀请群:</a-col>
                            <a-col class="value" :span="14">
                                <a-tag color="blue" v-for="item in groupList" :key="item._id">{{item.nickname}}</a-tag>
                            </a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">邀请好友:</a-col>
                            <a-col class="value" :span="14">
                                <a-tag color="blue" v-for="item in customerList" :key="item._id">{{item.username}}</a-tag>
                            </a-col>
                        </a-row>
                        
                    </div>
        </a-modal>
    </div>
</template>
<script>
const columns = [
     {
        title: '标题',
        dataIndex: 'title',
        align:'center',
    },
    {
        title:'描述',
        dataIndex:'desc',
        align:'center'
    },
    {
        title:'主播',
        dataIndex:'anchor',
        align:'center',
        scopedSlots: { customRender: 'anchor' },
    },
    {
        title:'状态',
        dataIndex:'status',
        align:'center',
        scopedSlots: { customRender: 'status' },
    },
     {
        title:'直播时长',
        dataIndex:'start_time',
        align:'center',
        scopedSlots: { customRender: 'long' },
    },
    {
        title:'创建时间',
        dataIndex:'create_time',
        align:'center',
        scopedSlots: { customRender: 'time' },
    },
    {
        title: '操作',
        align:'center',
        width:80,
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
    }
]
const multColumns = [
    {
      title: "群名称",
      dataIndex: "nickname",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'nickname' },
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      align: "center",
      scopedSlots: { customRender: 'time' },
    },
]
const customerColumns = [
    {
        title: '客户',
        dataIndex: 'username',
        align:'center',
        scopedSlots: { customRender: 'username' },
    },
    {
        title: '性别',
        dataIndex: 'sex',
        align:'center',
        scopedSlots: { customRender: 'sex' },
    }
]
import {
    mapState
  } from 'vuex'
export default {
    name:'live-setting',
    data(){
        return{
            searchForm:{
                title:"",
                anchor:'',
                status:'all',
                end_time:null,
                begin_time:null
            },
            addForm:{
                title:'',
                desc:'',
                anchor:'',
                room_list:[],
                user_list:[],
                room:'',
                manager:''
            },
            currentTab:'1',
            columns,
            multColumns,
            customerColumns,
            tableData:[],
            groupList:[],
            customerList:[],
            rooms:[],
            dateRange:undefined,
            loading:false,
            groupLoading:false,
            customerLoading:false,
            imgLoading:false,
            visible:false,
            detailVisible:false,
            detailObj:{},
            pagination: {
                total: 1,
                current: 1,
                pageSize: 20, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
        }
    },
    computed: {
        ...mapState(['mqttInit','robots'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleClickSearch()
                    this.getRooms()
                }
            },
            immediate:true
        },
    },
    methods:{
        tabChange(){

        },
        handleTableChange(pagination){
            this.pagination = pagination
        },
        handleDateChange (date, dateString) {
            this.searchForm.begin_time = new Date(dateString[0]).getTime()
            this.searchForm.end_time = new Date(dateString[1]).getTime()
        },
       handleClickSearch(){
           this.pagination.current = 1
           this.handleSearch()
       },
       handleSearch(){
           this.loading = true
           let payload = {
                msgId:'searchLive',
                data: {
                    page:this.pagination.current
                },
            };
            let {title,anchor,status,begin_time,end_time} = this.searchForm
            if(title){
                payload.data.title = title
            }
            if(status!="all"){
                payload.data.status = status
            }
            if(anchor){
                payload.data.anchor = anchor
            }
            if(begin_time){
                payload.data.begin_time = begin_time
                payload.data.end_time = end_time
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/live/search",
                payload,
                },
                (topic, data) => {
                    this.loading = false
                    this.tableData = data.res || []
                    this.pagination.total = data.count
                },
                this
            );
       },
       handleReset(){
           this.searchForm = {
                title:"",
                anchor:'',
                status:'all',
                end_time:null,
                begin_time:null
            }
       },
       handleGetDetail(row){
           this.detailObj = row
           this.getGroupByIds()
           this.getCustomerByIds()
           this.detailVisible = true
       },
       handleClickAdd(){
           this.addForm = {
                title:'',
                desc:'',
                anchor:'',
                room_list:[],
                user_list:[],
                room:'',
                manager:''
            }
           this.visible = true
       },
       selectedRoom(val){
           this.addForm.manager = this.rooms.find(item=>item.conversation_id == val).create_user_id
       },
       formatAnchor(val){
        //    console.log(this.robots.find(item=>item.user_id==val))
           return (this.robots.find(item=>item.user_id==val)||{}).username
       },
       handleSubmit(){
        //    this.addForm = {
        //         title:'',
        //         desc:'',
        //         img:'',
        //         anchor:'',
        //         room_list:[],
        //         user_list:[],
        //         room:'',
        //         manager:''
        //     }
            if(!this.addForm.title || !this.addForm.desc){
                this.$message.warning('请检查填写，不能为空')
                return
            }
            if(!this.addForm.anchor){
                this.$message.warning('请选择主播,主播要先托管')
                return
            }
            if(this.addForm.room_list.length === 0 && this.addForm.user_list.length ===0){
                this.$message.warning('请选择需要邀请的人或群')
                return
            }
            if(!this.addForm.room){
                this.$message.warning('请选择直播小助手')
                return
            }
            let payload = {
                msgId:'insertLive',
                data: {
                    ...this.addForm
                },
            };
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/live/insert",
                payload,
                },
                (topic, data) => {
                    if(data.success){
                        this.$message.success('创建成功')
                        this.visible = false
                    }else{
                        this.$message.error('创建失败，请联系管理员')
                    }
                },
                this
            );
       },
       getTimeLong(row){
           if(!row.start_time||!row.end_time){
               return ''
           }
           let time = row.end_time - row.start_time
           let s = parseInt(time/1000)
           if(s<60){
               return `${s}秒`
           }
           let m = parseInt(s/60) //100
           let m_y = s%60
           if(m<60){
               return `${m}分${m_y}秒`
           }
           let h = parseInt(m/60)
           let h_m = m%60
            return `${h}时${h_m}分${m_y}秒`
       },
       getRooms(){
           let payload = {
                msgId:'searchGroupByLive',
                data:{
                    live:true
                }
            }
            // console.log(payload)
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/searchGroupByPage",
                payload,
                },
                (topic, data) => {
                    // console.log(data)
                    this.rooms = data.res
                },
                this
            );
       },
       getGroupByIds(){
            let payload = {
                msgId:'searchGroupByIds',
                data:{
                    conversation_ids:this.detailObj.room_list,
                    live:false
                }
            }
            // console.log(payload)
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/searchGroupByPage",
                payload,
                },
                (topic, data) => {
                    // console.log(data)
                    this.groupList = data.res
                },
                this
            );
       },
       getCustomerByIds(){
           let payload = {
                msgId:'searchFriendsWP',
                data:{
                    _ids:this.detailObj.user_list
                }
            }
            // console.log(payload)
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/searchFriendsWP",
                payload,
                },
                (topic, data) => {
                    // console.log(data)
                    this.customerList = data.res
                },
                this
            );
       },
       beforeUpload(){

       },
        //上传图片响应
        handleImgChange(info){
            if (info.file.status === 'uploading') {
                this.imgLoading = true;
                return;
            }
            if (info.file.status === 'done') {
                this.imgLoading = false;
                this.addForm.img = info.file.response.url
                
            }
        }, 
        chooseMult(arr){
            this.addForm.room_list = arr
        },
        chooseCustomer(arr){
            this.addForm.user_list = arr
        },
        robotSelected(val){
            this.addForm.anchor = val
        },
        handleGetSearchRobots(val){
            this.searchForm.anchor = val
        },
       
    }
}
</script>
<style lang="scss" scoped>
// .detail-view{
//     max-height: 350px;
//     overflow: scroll;
// }
// .detail-view::-webkit-scrollbar{display:none;}
.detail-row{
    height: 60px;
    .label{
        text-align: right;
        margin-right: 40px;
    }
    .text{
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
}
</style>